import dayjs1 from "dayjs"
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"
// 默认北京时区
dayjs1.extend(utc)
dayjs1.extend(timezone)
dayjs1.tz.setDefault("Asia/Shanghai") // America/New_York  Asia/Shanghai
// 默认北京市区
const dayjs = dayjs1.tz

export default dayjs
