import dayjs from "@/utils/dayjsTool"
import _, { get as lodashGet, set as lodashSet } from "lodash"
import { 文件类型 } from "@/types/ConstantRecord"
import { workStore } from "@/store"

type Empty = null | "null" | "" | undefined

/**
 * 转换成数组
 *
 * @param {T} source
 * @returns
 */
export const toArray = <T>(source: T | Array<T>): Array<T> => {
    if (source instanceof Array) {
        return source
    }
    const result = source != null ? [source] : []
    return result
}

/**
 * lodash的 get函数超集，当取得值为null、 null、undefined,''将返回默认值
 * path支持数组，会依次选取优先级高的放前面
 * @static
 * @param {object} obj 源数据
 * @param {string|array} path 参数路径
 * @param {*} defaultValue 默认值
 * @returns
 *
 */
export const get = <T, K>(
    obj: T,
    path: string | Array<string | Symbol> | Symbol,
    defaultValue: K,
): K => {
    let value: K | null = null
    const rules: Array<K | Empty> = [null, "null", "", undefined]
    const pathList = toArray(path)
    for (const p of pathList) {
        if (typeof p === "symbol") {
            value = lodashGet(obj, p, null) as any
        } else {
            value = lodashGet(obj, `${p}`, null) as any
        }
        if (!rules.includes(value)) {
            return value as K
        }
    }
    return defaultValue
}

export const multipleObjectGet = <T, K>(
    objArr: Array<T>,
    path: string | Array<string>,
    defaultValue: K,
): K => {
    for (const obj of objArr) {
        const value = get(obj, path, null)
        if (value) {
            return value
        }
    }
    return defaultValue
}

export const set = <T extends Record<string, any> | null, K>(
    obj: T,
    path: string,
    value: K,
): void => {
    lodashSet<T>(obj as any, path, value)
}

/**
 * 不会报错的json parse
 *
 * @param {*} obj
 * @returns
 */
export const jsonpParse = <T>(obj: string | null): T | null => {
    try {
        if (obj == null) {
            return null
        }
        return JSON.parse(obj)
    } catch (error) {
        return null
    }
}

interface TypeDict {
    Number: string
    Undefined: string
    Object: string
    Array: string
    String: string
    Null: string
    NaN: string
    Function: string
    Date: string
}

/**
 * 判断元素的正确类型
 *
 * @param {*} obj
 * @returns {keyof TypeDict}
 */
export const getType = (obj: any): keyof TypeDict => {
    const result = Object.prototype.toString.call(obj)
    const typeStr: keyof TypeDict = result.substring(8, result.length - 1) as keyof TypeDict
    if (obj !== obj) {
        return "NaN"
    } else {
        return typeStr
    }
}

/**
 * 函数运行时间诊断
 *
 * @param {*} fn
 * @param {string} str
 */
export const measuringTime = async (fn: any, str: string) => {
    const t = dayjs()
    await fn()
    console.log(str, `${dayjs().diff(t, "ms")}`)
}

export const blobToString = (blob: Blob): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onloadend = () => {
            resolve(reader.result as string)
        }
        reader.onerror = reject
        reader.readAsText(blob)
    })
}

/**
 * 数组转字符串
 *
 * @param {string[]} arr
 * @return {*}
 */
export const arrToMap = (arr: string[]) => {
    const result = arr.reduce<Record<string, boolean>>((pre, current) => {
        pre[current] = true
        return pre
    }, {})
    return result
}

export const setByKey = (key: string, v: any) => {
    return (o: any) => {
        lodashSet(o, key, v)
        return _.clone(o)
    }
}

/**
 * 判断是否为空
 *
 * @param {*} obj
 * @return {*}
 */
export const isEmpty = (obj: any) => {
    if (obj == null) {
        return true
    } else if (obj === "") {
        return true
    } else if (["undefined", "null"].includes(obj)) {
        return true
    }
    return false
}

/**
 * Form表单转换所有图片类型的值
 *
 * @param {*} params
 * @return {*}
 */
export const transferImageValueByParams = (params: Record<string, any>) => {
    for (let [key, v] of Object.entries(params)) {
        if (v === undefined) {
            delete params[key]
            continue
        }
        if (v?.file?.type === 文件类型.图片) {
            params[key] = v.file.url
        }
    }
    return params
}

/**
 * Form表单转换数组值为字符串
 *
 * @param {*} params
 * @return {*}
 */
export const transferArrayToString = (
    params: Record<string, any>,
    transferArrayKeys?: string[],
) => {
    const normalTransferArrayKeys = ["clientTypes", "memberIds", "userLevelIds", "vipLevelIds"]
    transferArrayKeys = transferArrayKeys || normalTransferArrayKeys
    for (let i of transferArrayKeys) {
        if (Array.isArray(params[i])) {
            params[i] = params[i].join(",")
        }else{
            console.log('未转换字段',i)
        }
    }
}

/**
 * Form表单转换表单列表值
 *
 * @param {*} params
 * @return {*}
 */
export const transferFormList = (params: Record<string, any>, transferFormListKeys?: string[]) => {
    for (let [key, value] of Object.entries(params)) {
        if (
            (!transferFormListKeys && key.startsWith("_formList")) ||
            (transferFormListKeys && transferFormListKeys.includes(key))
        ) {
            for (let i of value) {
                transferImageValueByParams(i)
                for (let [k, v] of Object.entries(i)) {
                    if (k === "_languageName") {
                        continue
                    }
                    if (params[k] === undefined) {
                        params[k] = {}
                    }
                    params[k][i._languageName] = v
                }
            }
            delete params[key]
        }
    }
}

interface TransferProps {
    isTransferArray?: boolean
    transferArrayKeys?: string[]
    isTransferFormList?: boolean
    transferFormListKeys?: string[]
}

/**
 * Form表单值通用转换器
 *
 * @param {*} params
 * @return {*}
 */
export const transferParamsValues = (
    params: Record<string, any>,
    transferProps?: TransferProps,
) => {
    const { isTransferArray, transferArrayKeys, isTransferFormList, transferFormListKeys } =
        transferProps || {}
    if (isTransferFormList) {
        transferFormList(params, transferFormListKeys)
    }
    transferImageValueByParams(params)
    if (isTransferArray) {
        transferArrayToString(params, transferArrayKeys)
    }
    return params
}

/**
 * 表单列表值转换为表单值
 *
 * @param {*} params
 * @return {*}
 */
export const transferResponseToFormList = (
    params: Record<string, any>,
    formListMap: Record<string, string[]>,
) => {
    for (let [paramsKey, formListKeys] of Object.entries(formListMap)) {
        const formListValue: Array<any> = []
        const languages = Object.keys(workStore.globalEnumInside.语言类型)
        const languageObj: Record<any, any> = {}

        for (let field of formListKeys) {
            if (!params[field]) {
                continue
            }
            for (let language of languages) {
                if (languageObj[language] === undefined) {
                    languageObj[language] = {
                        _languageName: language,
                    }
                }
                languageObj[language][field] = params[field][language]
            }
        }
        for (let language of languages) {
            formListValue.push(languageObj[language] ?? {})
        }

        params[paramsKey] = formListValue
    }
}

export const checkFunctionAsync = (fn: any) => {
    if (get(fn, "constructor.name", null) === "AsyncFunction" || fn instanceof Promise) {
        return true
    }
    return false
}

/**
 * 通过key获取enum值
 *
 * @param {string} key
 * @param {*} value
 * @return {*}
 */
export const getEnumValue = (key: string | number, value: any) => {
    const map = get(workStore.globalEnumKV, String(key), {}) as Array<any>
    let result = map[value]
    return result ?? value
}

/**
 * 通用时间参数合并器
 *
 * @param {*} params
 * @return {*}
 */
export const transferTimeValues = (res: Record<string, any>[], timeKeys: string[] = ["__Time"]) => {
    for (let i of res) {
        for (let timeKey of timeKeys) {
            let startTimeKey = timeKey.replace("__", "start")
            let endTimeKey = timeKey.replace("__", "end")
            if (!timeKey.startsWith("__")) {
                startTimeKey = timeKey.replace("__", "Start")
                endTimeKey = timeKey.replace("__", "End")
            }
            // if (i[startTimeKey] && i[endTimeKey]) {
            //     i[`${timeKey}`] = [i[startTimeKey], i[endTimeKey]]
            // }
            if (i[startTimeKey]) {
                i[`${timeKey}`] = [i[startTimeKey], i[endTimeKey]]
            } else {
                i[`${timeKey}`] = []
            }
        }
    }
    return res
}

/**
 * 通用时间参数切割器
 *
 * @param {*} params
 * @return {*}
 */
export const splitTimeValues = (params: Record<string, any>, timeKeys: string[] = ["__Time"]) => {
    for (let timeKey of timeKeys) {
        let startTimeKey = timeKey.replace("__", "start")
        let endTimeKey = timeKey.replace("__", "end")
        if (!timeKey.startsWith("__")) {
            startTimeKey = timeKey.replace("__", "Start")
            endTimeKey = timeKey.replace("__", "End")
        }
        if (params[timeKey]) {
            params[startTimeKey] = params[timeKey][0]
            params[endTimeKey] = params[timeKey][1]
            delete params[timeKey]
        }
    }
    return params
}

/**
 * 通用区间参数合并器
 *
 * @param {*} params
 * @return {*}
 */
export const combineRangeValues = (res: Record<string, any>[], keys: string[] = []) => {
    for (let i of res) {
        for (let key of keys) {
            const upKey = key.charAt(0).toUpperCase() + key.slice(1)
            let minKey = `min${upKey}`
            let maxKey = `max${upKey}`
            i[`${key}`] = [i[minKey], i[maxKey]]
        }
    }
    return res
}

interface MemberStatus {
    loginLock: boolean
    gameLock: boolean
    depositLock: boolean
    hasNormal?: boolean
}

/**
 * 会员状态字段转换为十进制数
 *  * 登陆锁定  游戏锁定  充提锁定
 *  * 0        0        0
 *  * 1        1        1
 *
 * {
 *   "0": "正常",
 *   "1": "充提锁定",
 *   "2": "游戏锁定",
 *   "3": "充提锁定/游戏锁定",
 *   "4": "登陆锁定",
 *   "5": "充提锁定/登陆锁定",
 *   "6": "登陆锁定/游戏锁定",
 *   "7": "登陆锁定/登陆锁定/充提锁定"
 * }
 *
 */
export const transferMemberStatus = (memberStatus: Array<any>) => {
    const loginLock = memberStatus.includes("loginLock")
    const gameLock = memberStatus.includes("gameLock")
    const depositLock = memberStatus.includes("depositLock")

    let status = 0
    if (loginLock) {
        status += 4
    }
    if (gameLock) {
        status += 2
    }
    if (depositLock) {
        status += 1
    }
    return status
}

/**
 * 会员状态字段转换为二进制数
 */
export const transferMemberStatusToBinary = (status: number) => {
    const obj = {
        loginLock: false,
        gameLock: false,
        depositLock: false,
    }
    if (status >= 4) {
        obj.loginLock = true
        status -= 4
    }
    if (status >= 2) {
        obj.gameLock = true
        status -= 2
    }
    if (status >= 1) {
        obj.depositLock = true
        status -= 1
    }
    return obj
}

export const transferMaintenananceType = (memberStatus: Array<any>) => {
    const app = memberStatus.includes("app")
    const h5 = memberStatus.includes("h5")
    const backend = memberStatus.includes("backend")

    let status = 0
    if (app) {
        status += 4
    }
    if (h5) {
        status += 2
    }
    if (backend) {
        status += 1
    }
    return status
}

/**
 * 会员状态字段转换为二进制数
 */
export const transferMaintenananceTypeToBinary = (status: number) => {
    const obj = {
        app: false,
        h5: false,
        backend: false,
    }
    if (status >= 4) {
        obj.app = true
        status -= 4
    }
    if (status >= 2) {
        obj.h5 = true
        status -= 2
    }
    if (status >= 1) {
        obj.backend = true
        status -= 1
    }
    return Object.keys(obj)
}

/**
 * 会员状态字段转换为十进制数组
 */
export const transferMemberStatusArr = (memberStatus: Array<any>) => {
    const loginLock = memberStatus.includes("loginLock")
    const gameLock = memberStatus.includes("gameLock")
    const depositLock = memberStatus.includes("depositLock")
    const hasNormal = memberStatus.includes("hasNormal")
    let status = []
    if (loginLock) {
        status.push(4)
    }
    if (gameLock) {
        status.push(2)
    }
    if (depositLock) {
        status.push(1)
    }
    if (hasNormal) {
        status.push(0)
    }
    if (status.length === 0) {
        return null
    }
    return status
}

/**
 * 对象转url查询参数字符串
 *
 * @param {Record<string, any>} obj
 * @return {*}
 */
export const objToQuery = (obj: Record<string, any>) => {
    const result: string[] = []
    Object.entries(obj).forEach((entries) => {
        const [key, value] = entries
        const value_ = value as string
        result.push(`${key}=${encodeURIComponent(value_)}`)
    })
    return result.join("&")
}

/**
 * 查询参数转对象
 *
 * @return {IObject}  {IObject}
 */
export const urlQueryToObj = (str?: string): Record<string, any> => {
    const url = str || window.location.href
    const result: Record<string, any> = {}
    if (url.includes("?")) {
        const urlSplit = url.split("?")
        const len = urlSplit.length - 1
        const queryParam = urlSplit[len] || ""
        queryParam
            .split("&")
            .filter((str) => str !== "")
            .forEach((str) => {
                const [key, value] = str.split("=")
                result[key] = decodeURIComponent(value)
            })
        return result
    }
    return result
}

export const isValueStringEqualOrIncludes = (a: any, b: any) => {
    if (a instanceof Array) {
        return a.map((i) => String(i)).includes(String(b))
    } else {
        return String(a) === String(b)
    }
}

export const getCompanyCodeStr = () => {
    const companyCodeStr = window.location.host.match(/^([\w\d]{1,100})\./)?.[1]
    if (companyCodeStr && companyCodeStr !== "admin" && companyCodeStr !== "localhost") {
        return companyCodeStr
    }
    return null
}

export const convertTwoDecimal = (amount: any) => {
    if (!amount) {
        amount = 0
    }
    const parsedAmount = parseFloat(amount)
    if (isNaN(parsedAmount)) {
        return amount
    }
    return parsedAmount.toFixed(2)
}